body {
  background-color: #121213;
  color: white;
}

.puzzle {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.board.solved {
  animation-name: grow;
  animation-duration: 0.5s;
}

.title {
  font-family: "Viga", sans-serif;
  font-weight: bold;
  font-size: 2rem;
  border-bottom: #818384 1px solid;
  text-align: center;
  color: white;
}

.title img {
  height: 2rem;
  width: auto;
  display: inline-block;
  margin-right: 1rem;
}

.title select {
  vertical-align: middle;
  margin-left: 2rem;
}

input {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  position: absolute;
  color: RGBA(0, 0, 0, 0);
}

.letter {
  border: RGBA(255, 255, 255, 0) 1px solid;
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  line-height: 4rem;
  font-weight: bold;
  width: 4rem;
  height: 4rem;
  display: inline-block;
  margin: 0.2rem;
  padding: 0;
}

@keyframes grow {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}

.letter:not(:empty) {
  animation-name: grow;
  animation-duration: 0.3s;
}

.letter.unknown {
  border: #818384 1px solid;
}

.incorrect,
.displaced,
.correct {
  transform: rotateY(360deg);
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.incorrect {
  background-color: #3a3a3c;
}

.displaced {
  background-color: #b59f3b;
}

.correct {
  background-color: #538d4e;
}

.keyboard {
  width: 24rem;
}

.keyboard .letter {
  width: 1.88rem;
  height: 2rem;
  font-size: 1rem;
  line-height: 2rem;
  border-radius: 15%;
  color: white;
}

.keyboard .letter[data-key="a"] {
  margin-left: 1.5rem;
}

.keyboard .letter[data-key="Enter"] {
  font-size: 0.5rem;
  width: 2.5rem;
}

.keyboard .letter[data-key="⌫"] {
  width: 2.5rem;
}

.keyboard .letter.unknown {
  background-color: #818384;
}

.meta {
  border: #818384 1px solid;
}

.meta span {
  padding-left: 0.1rem;
  display: block;
  font-size: 0.6rem;
  line-height: 1rem;
  text-align: left;
}

.meta .expected::before {
  content: "ers: ";
}

.meta .remaining::before {
  content: "rs: ";
}

.meta .luck::before {
  content: "luck: ";
}

.meta .skill::before {
  content: "skill: ";
}

button {
  margin-left: 0.25rem;
}

@keyframes shake {
  0% {
    margin-left: 0.5rem;
  }
  10% {
    margin-left: -0.5rem;
  }
  20% {
    margin-left: 0.5rem;
  }
  30% {
    margin-left: -0.5rem;
  }
  40% {
    margin-left: 0.5rem;
  }
  50% {
    margin-left: -0.5rem;
  }
  60% {
    margin-left: 0.5rem;
  }
  70% {
    margin-left: -0.5rem;
  }
  80% {
    margin-left: 0.5rem;
  }
  90% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}

.puzzle.error::before {
  content: "Invalid\00a0Selection";
  font-size: 1.5rem;
  position: absolute;
  left: 50%;
  top: 39%;
  z-index: 10;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 4rem;
  border: 2px white solid;
  background-color: #3a3a3c;
  padding: 0 1rem 0 1rem;
}
.puzzle.error .focused {
  animation-name: shake;
  animation-duration: 1s;
}
